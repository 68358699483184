// console.log("Elevenpack javascript is loaded");

// @require "./bootstrap.min"

// import './jquery.min';
// import $ from "jquery";
// import './bootstrap.min';
// import './flexslider.min';

// <script src="js/jquery.min.js"></script>
//                 <script src="js/bootstrap.min.js"></script>
//                 <script src="js/flexslider.min.js"></script>
//                 <script src="js/twitterfetcher.min.js"></script>
//                 <script src="js/spectragram.min.js"></script>
//                 <script src="js/smooth-scroll.min.js"></script>
//                 <script src="js/parallax.min.js"></script>
//                 <script src="js/scripts.js"></script>

var mobileToggle = document.querySelector(".mobile-toggle");
var navBar = document.querySelector(".nav-bar")

mobileToggle.onclick = function NavToggle() {
  console.log("hello")
  navBar.classList.toggle("nav-open")
  mobileToggle.classList.toggle("active")
}
